<template>
  <div>
    <a-modal
      width="1150px"
      v-model="isShow"
      centered
      :mask-closable="false"
      :loading="submitting"
      title="编辑客户套餐"
      @ok="handleSubmit"
    >
      <a-spin :spinning="spinning">
        <a-form
          class="iotplt-compact-form"
          :form="form"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 15 }"
          @submit="handleSubmit"
        >
          <a-row>
            <!--基本信息-->
            <a-col :span="12">
              <span class="item-title">基本信息</span>
              <a-form-item label="客户套餐名称">
                <a-input
                  v-decorator="['name', {
                    normalize: this.$lodash.trim,
                    rules: [
                      { required: true, message: '请输入客户套餐名称' },
                      { max: 100, message: '最多100个字符' },
                    ]
                  }]"
                />
              </a-form-item>

              <a-form-item
                label="套餐名称"
                class="iotplt-display-item"
              >
                {{ data.top_agent_product_name }}
              </a-form-item>

              <a-form-item
                label="运营商种类"
                class="iotplt-display-item"
              >
                {{ data.carrier_type }}
              </a-form-item>

              <a-form-item
                label="用户业务模式"
                class="iotplt-display-item"
              >
                {{ data.user_business_type }}
              </a-form-item>

              <a-form-item
                label="套餐周期"
                class="iotplt-display-item"
              >
                {{ data.service_cycle }}
              </a-form-item>

              <a-form-item
                label="套餐容量"
                class="iotplt-display-item"
              >
                {{ data.package_capacity }}
              </a-form-item>

              <a-form-item
                label="语音"
                class="iotplt-display-item"
              >
                {{ data.voice_capacity }}
              </a-form-item>

              <a-form-item label="套餐描述">
                <a-textarea
                  v-decorator="['description', {
                    rules: [
                      { max: 200, message: '最多200个字符' },
                    ]
                  }]"
                />
              </a-form-item>
            </a-col>

            <!--价格设置-->
            <a-col :span="12">
              <span class="item-title">价格设置</span>

              <a-row
                :gutter="24"
                style="padding-left: 2px; margin-top: 10px; margin-bottom: 10px"
              >
                <a-col :span="8">周期数</a-col>
                <a-col :span="8">套餐价格(元)</a-col>
                <a-col :span="8">客户价格(元)*</a-col>
              </a-row>

              <a-row
                :gutter="24"
                v-for="(item, index) in agentsProductFavorablePrices"
                :key="item.id"
              >
                <a-col :span="8">
                  <a-form-item class="iotplt-display-item">
                    {{ item.cycles }}
                  </a-form-item>
                </a-col>

                <a-col :span="8">
                  <a-form-item class="iotplt-display-item">
                    {{ item.top_agent_distributor_price | formatCurrency }}
                  </a-form-item>
                </a-col>

                <a-col :span="8">
                  <a-form-item class="iotplt-display-item">
                    <a-input-number
                      :min="0"
                      :max="10000000"
                      :step="0.01"
                      :precision="2"
                      style="width: 120px"
                      :disabled="isMerchantSales"
                      v-decorator="[`agents_product_favorable_prices[${index}].distributor_price`, {
                        initialValue: item.distributor_price >= 0 ? item.distributor_price : undefined,
                        rules: [
                          { required: true, message: '请输入客户价格' }
                        ]
                      }]"
                    />
                  </a-form-item>
                </a-col>
              </a-row>

              <a-row style="margin-top: 10px" :gutter="24" v-if="isShowExcessRateItem">
                <a-col :span="8">
                  超额计费单位
                </a-col>
                <a-col :span="8">
                  价格(元)
                </a-col>
                <a-col :span="8">
                  客户价格(元)*
                </a-col>
              </a-row>

              <a-row style="margin-top: 10px" :gutter="24" v-if="isShowExcessRateItem">
                <a-col :span="8">
                  <a-form-item class="iotplt-display-item">
                    {{ data.excess_rate_quantity }}
                  </a-form-item>
                </a-col>

                <a-col :span="8">
                  <a-form-item class="iotplt-display-item">
                    {{ data.top_agent_excess_distributor_price | formatCurrency }}
                  </a-form-item>
                </a-col>

                <a-col :span="8">
                  <a-form-item>
                    <a-input-number
                      :min="0"
                      :max="10000000"
                      :step="0.01"
                      :precision="2"
                      style="width: 120px"
                      :disabled="isMerchantSales"
                      v-decorator="['excess_distributor_price', {
                        initialValue: data.excess_distributor_price < 0 ? undefined : data.excess_distributor_price,
                        rules: [
                          { required: true, message: '请输入客户价格' }
                        ]
                      }]"
                    />
                  </a-form-item>
                </a-col>
              </a-row>

              <a-row style="margin-top: 10px" :gutter="24" v-if="isShowStopItem">
                <a-col :span="8">
                  停机保号价格(元)
                </a-col>
                <a-col :span="8">
                  停机保号客户价格(元)*
                </a-col>
              </a-row>

              <a-row style="margin-top: 10px" :gutter="24" v-if="isShowStopItem">
                <a-col :span="8">
                  <a-form-item class="iotplt-display-item">
                    {{ data.top_agent_stop_distributor_price | formatCurrency }}
                  </a-form-item>
                </a-col>

                <a-col :span="8">
                  <a-form-item>
                    <a-input-number
                      :min="0"
                      :max="10000000"
                      :step="0.01"
                      :precision="2"
                      style="width: 120px"
                      v-decorator="['stop_distributor_price', {
                        initialValue: data.stop_distributor_price < 0 ? undefined : data.stop_distributor_price,
                        rules: [
                          { required: true, message: '请输入客户价格' }
                        ]
                      }]"
                    />
                  </a-form-item>
                </a-col>
              </a-row>

              <span class="item-title">卡片设置</span>

              <a-row style="margin-top: 10px; margin-bottom: 10px" :gutter="24">
                <a-col :span="8">
                  卡片材质
                </a-col>
                <a-col :span="8">
                  卡板价格(元)
                </a-col>
                <a-col :span="8">
                  客户价格(元)*
                </a-col>
              </a-row>

              <a-row
                :gutter="24"
                v-for="(cardType, index) in agentsProductsCardTypes"
                :key="cardType.id"
              >
                <a-col :span="8">
                  <a-form-item class="iotplt-display-item">
                    {{ cardType.card_type_name }}
                  </a-form-item>
                </a-col>

                <a-col :span="8">
                  <a-form-item class="iotplt-display-item">
                    {{ cardType.top_agent_price | formatCurrency }}
                  </a-form-item>
                </a-col>

                <a-col :span="8">
                  <a-form-item>
                    <a-input-number
                      :min="0"
                      :max="10000000"
                      :step="0.01"
                      :precision="2"
                      style="width: 120px"
                      v-decorator="[`agents_products_card_types[${index}].price`, {
                        initialValue: cardType.price < 0 ? undefined : cardType.price,
                        rules: [
                          { required: true, message: '请输入客户价格' }
                        ]
                      }]"
                    />
                  </a-form-item>
                </a-col>
              </a-row>
            </a-col>
          </a-row>
        </a-form>
      </a-spin>
    </a-modal>
  </div>
</template>

<script>
import { findAgencyAgentsProductByTopAgentForm, updateAgencyAgentsProductByTopAgent } from '@/api/agents_product'

export default {
  name: 'EditAgentsProduct',
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      isShowExcessRateItem: true, // 是否显示设置超额参数一项
      isShowStopItem: false, // 是否显示设置停机保号参数一项
      isMerchantSales: false,
      data: {},
      agentsProductFavorablePrices: {
        type: Object
      },
      agentsProductsCardTypes: {},
      spinning: true,
      isAllowRetail: false, // 是否允许零售
      submitting: false,
      form: this.$form.createForm(this, { name: 'agents_product' })
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  created() {
    this.fetchFormData()
  },
  methods: {
    fetchFormData() {
      this.spinning = true
      findAgencyAgentsProductByTopAgentForm(this.id).then((res) => {
        if (res.code === 0) {
          this.data = res.data
          this.agentsProductFavorablePrices = res.data.agents_product_favorable_prices
          this.agentsProductsCardTypes = res.data.agents_products_card_types
          // 处理零售价格
          this.agentsProductFavorablePrices.forEach((value) => {
            if (value.retail_price === -1) {
              value.retail_price = undefined
            }
          })

          this.isAllowRetail = this.data.is_allow_retail

          if (this.data.user_business_type_slug === 'custom_day' || this.isAllowRetail) {
            this.isShowExcessRateItem = false
          }

          if (this.data.user_business_type_slug === 'custom_month' || this.data.user_business_type_slug === 'traffic_share') {
            this.isShowStopItem = true
          }

          if (this.data.user_business_type_slug === 'merchant_sales') {
            this.isMerchantSales = true
          }

          this.form.setFieldsValue({
            name: this.data.name,
            description: this.data.description
          })
        }

        this.spinning = false
      })
    },

    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }

      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitting = true

          const agentsProductFavorablePrices = []
          const checkPriceValidators = []
          values.agents_product_favorable_prices.forEach((favorablePrice, index) => {
            if (this.agentsProductFavorablePrices[index].top_agent_distributor_price > favorablePrice.distributor_price) {
              checkPriceValidators.push(false)
            } else {
              checkPriceValidators.push(true)
            }
            agentsProductFavorablePrices.push({
              cycles: this.agentsProductFavorablePrices[index].cycles,
              distributor_price: favorablePrice.distributor_price
            })
          })

          const agentsProductsCardTypes = []
          if (values.agents_products_card_types) {
            values.agents_products_card_types.forEach((cardType, index) => {
              agentsProductsCardTypes.push({
                card_type_id: this.agentsProductsCardTypes[index].card_type_id,
                price: cardType.price
              })
            })
          }

          const data = {
            ...values,
            agents_products_card_types: agentsProductsCardTypes,
            agents_product_favorable_prices: agentsProductFavorablePrices
          }

          if (checkPriceValidators.includes(false)) {
            const vm = this
            this.$confirm({
              title: '设置的客户价中存在小于同周期节点下套餐价格的情况，确定保存吗？',
              content: '',
              okText: '确定',
              okType: 'danger',
              cancelText: '取消',
              onOk() {
                vm.updateProduct(data)
              }
            })
          } else {
            this.updateProduct(data)
          }

          this.submitting = false
        }
      })
    },

    updateProduct(data) {
      updateAgencyAgentsProductByTopAgent(this.id, data).then((res) => {
        if (res.code === 0) {
          // 关闭模态框
          this.isShow = false
          // 告知父组件已完成
          this.$emit('completed')
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
  .item-title {
    color: @primary-color;
  }
</style>
